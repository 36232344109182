import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    InputAdornment,
    TableSortLabel,
    Typography,
    Checkbox,
} from '@material-ui/core';

import { AllocationAlgorithmTypeEnum } from '../../../../../../../app-config';
import { Alert } from '@material-ui/lab';

export const ParamTypes = {
    PERCENTAGE: 'Pourcentage',
    CHECKBOX: 'Sélection',
    PRIORITY: 'Priorité',
    SHARE: 'Part(s)',
};

const PARAM_COLUMN = 'param';
const ASC = 'asc';
const DESC = 'desc';

// TNR uses data-id prop to locate inputs. Then this counter permits to have consistent data-id
let dataIdCounter = 0;

export const getParamType = (algoType) => {
    if (algoType === AllocationAlgorithmTypeEnum.STATIC) {
        return ParamTypes.PERCENTAGE;
    }
    if (algoType === AllocationAlgorithmTypeEnum.BY_DEFAULT) {
        return ParamTypes.CHECKBOX;
    }
    if (algoType === AllocationAlgorithmTypeEnum.BY_SHARE_WITH_MINIMUM) {
        return ParamTypes.SHARE;
    }
    return ParamTypes.PRIORITY;
};

const useStyles = makeStyles((theme) => ({
    stickyRow: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
    },
    clickableRow: {
        cursor: 'pointer',
    },
    checkbox: {
        padding: '0px',
    },
}));

const isLetter = (str) => {
    return str.length === 1 && str.match(/[a-z]/i);
};

function sort(participants, params, order, orderBy) {
    const up = order === ASC ? 1 : -1;
    let sortedParticipants;
    if (orderBy === PARAM_COLUMN) {
        sortedParticipants = participants.sort((a, b) => {
            const aValue = Number(params[a.id] ?? 0);
            const bValue = Number(params[b.id] ?? 0);
            return aValue < bValue ? -up : up;
        });
    } else {
        sortedParticipants = participants.sort((a, b) => {
            return a[orderBy] < b[orderBy] ? -up : up;
        });
    }
    return sortedParticipants;
}

function GlobalParamWidget({
    paramType,
    participants,
    participantParams,
    handleParamChange,
}) {
    const classes = useStyles();

    if (paramType !== ParamTypes.CHECKBOX) {
        return null;
    }

    const handleSelectAllClick = (event) => {
        participants.forEach((participant) => {
            handleParamChange(participant.id, event.target.checked)();
        });
    };

    const nSelectedParticipants = Object.keys(participantParams).length;
    const allChecked =
        participants.length > 0 &&
        nSelectedParticipants === participants.length;
    return (
        <Checkbox
            className={classes.checkbox}
            checked={allChecked}
            onChange={handleSelectAllClick}
            inputProps={{
                'aria-label': 'Sélectionner tous les points',
                title: 'Sélectionner tous les points',
            }}
        />
    );
}

function ConfigTableHead({
    columns,
    paramType,
    participants,
    participantParams,
    order,
    orderBy,
    handleRequestSort,
    handleParamChange,
}) {
    const classes = useStyles();
    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    return (
        <TableHead className={`${classes.stickyRow}`}>
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        style={{ width: column.width }}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        {column.id === PARAM_COLUMN && (
                            <GlobalParamWidget
                                paramType={paramType}
                                participants={participants}
                                participantParams={participantParams}
                                handleParamChange={handleParamChange}
                            />
                        )}
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : ASC}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function InputWidget({ type, classes, prefix, param, onChange }) {
    dataIdCounter += 1; // Used by TNR
    if (type === ParamTypes.CHECKBOX) {
        return (
            <Checkbox
                className={classes.checkbox}
                checked={param}
                inputProps={{
                    'data-id': 'checkbox-' + dataIdCounter, // Used by TNR
                    'aria-labelledby': prefix,
                }}
            />
        );
    }

    let min = null;
    let unit = null;
    if (type === ParamTypes.PERCENTAGE) {
        min = 0;
        unit = '%';
    } else if (type === ParamTypes.PRIORITY || type === ParamTypes.SHARE) {
        min = 1;
    }
    const ignoredKey = ['.', ',', '-', '+'];
    return (
        <TextField
            type="number"
            id={prefix}
            onChange={onChange}
            onKeyDown={(event) => {
                const key = event?.key;
                if (ignoredKey.includes(key) || isLetter(key)) {
                    event.preventDefault();
                }
            }}
            value={param || ''}
            InputProps={{
                'data-id': 'textfield-' + dataIdCounter, // Used by TNR
                inputProps: {
                    min: min,
                },
                ...(unit && {
                    endAdornment: (
                        <InputAdornment position="end">{unit}</InputAdornment>
                    ),
                }),
            }}
        />
    );
}

function ParticipantRow({
    prefix,
    classes,
    columns,
    participant,
    paramType,
    param,
    handleParamChange,
}) {
    const isClickable = paramType === ParamTypes.CHECKBOX;
    const isSelected = param ?? 0;
    return (
        <TableRow
            key={`${prefix}-row`}
            tabIndex={-1}
            hover
            className={isClickable ? classes.clickableRow : null}
            onClick={
                isClickable
                    ? handleParamChange(participant.id, !isSelected)
                    : null
            }
            selected={isClickable ? isSelected : null}
        >
            {columns.map((column) => {
                return (
                    <TableCell key={`${prefix}-${column.id}`}>
                        {column.id === PARAM_COLUMN ? (
                            <InputWidget
                                type={column.label}
                                classes={classes}
                                prefix={`${prefix}-input`}
                                param={param ?? 0}
                                onChange={handleParamChange(participant.id)}
                            />
                        ) : (
                            participant[column.id]
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}

const WarningPercentageHigherThan100 = ({ percentages }) => {
    const total = percentages.reduce(
        (acc, percentage) => acc + Number(percentage),
        0
    );
    if (total > 100) {
        return (
            <Alert severity="error" style={{ marginTop: '10px' }}>
                La somme des pourcentages ne peux pas être supérieure à 100%
                (elle est actuellement à {total}%).
            </Alert>
        );
    } else {
        return (
            <Typography style={{ marginTop: '10px', textAlign: 'right' }}>
                La somme des coefficients fait {total}%.
            </Typography>
        );
    }
};

function ConfigTable({
    prefix,
    participants,
    participantParams,
    paramType,
    handleParamChange,
    maxHeight = '100%',
}) {
    dataIdCounter = 0; // Used by TNR
    const columns = [
        { id: 'name', label: 'Nom', width: '60%' },
        { id: 'dsoId', label: 'PRM', width: '20%' },
        { id: PARAM_COLUMN, label: paramType, width: '20%' },
    ];

    const classes = useStyles();

    // Component state ========================================================
    const [order, setOrder] = React.useState(DESC);
    const [orderBy, setOrderBy] = React.useState(PARAM_COLUMN);

    // Callbacks ==============================================================
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === ASC;
        setOrder(isAsc ? DESC : ASC);
        setOrderBy(property);
    };

    // Renders & utils ========================================================
    return (
        <>
            <TableContainer style={{ maxHeight: maxHeight }}>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <ConfigTableHead
                        columns={columns}
                        paramType={paramType}
                        participants={participants}
                        participantParams={participantParams}
                        order={order}
                        orderBy={orderBy}
                        handleRequestSort={handleRequestSort}
                        handleParamChange={handleParamChange}
                    />
                    <TableBody>
                        {sort(
                            participants,
                            participantParams,
                            order,
                            orderBy
                        ).map((participant) => {
                            const prefixRow =
                                prefix + '-participant-' + participant.id;
                            return (
                                <ParticipantRow
                                    key={prefixRow}
                                    prefix={prefixRow}
                                    classes={classes}
                                    columns={columns}
                                    paramType={paramType}
                                    participant={participant}
                                    param={participantParams[participant.id]}
                                    handleParamChange={handleParamChange}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {paramType === ParamTypes.PERCENTAGE && (
                <WarningPercentageHigherThan100
                    percentages={Object.values(participantParams)}
                />
            )}
        </>
    );
}

export default ConfigTable;
